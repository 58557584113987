<template>
  <div class="page">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam="searchParam"
    >
      <template #headBtnSlot>
        <v-button text="新增" @click="add()" />
      </template>
      <template #searchSlot>
        <v-select
          clearable
          :options="typeList"
          v-model="searchParam.type"
          label="分类"
        />
        <v-input
          label="标题"
          v-model="searchParam.title"
          placeholder="请输入标题"
        />
      </template>
      <template #operateSlot="scope">
        <v-button text="编辑" type="text" @click="edit(scope.row)" />
        <v-button text="删除" type="text" @click="remove(scope.row)" />
      </template>
    </v-list>
    <el-dialog
      :close-on-click-modal="false"
      :title="dialogTitle"
      :visible.sync="dialogFormVisible"
      width="50%"
    >
      <el-form label-position="right" label-width="100px">
        <el-form-item label="标题：">
          <v-input clearable class="filter-item" v-model="temp.title"></v-input>
        </el-form-item>
        <el-form-item label="封面图：">
          <v-upload :limit="1" :imgUrls.sync="temp.image" />
        </el-form-item>
        <el-form-item label="分类：">
          <v-select clearable :options="typeList" v-model="temp.type" />
        </el-form-item>
        <el-form-item label="内容：">
          <div id="editor">
            <v-ueditor v-model="temp.content" />
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer dialogBottomBtn">
        <v-button
          size="medium"
          @click="dialogFormVisible = false"
          text="返回"
        ></v-button>
        <v-button size="medium" @click="submit()" text="确认"></v-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getDecorationTipsListUrl,
  addOrUpdateTipsInfo,
  deleteTipsInfoUrl,
} from "./api.js";
export default {
  name: "decorationTips",
  data() {
    return {
      dialogTitle: "",
      dialogFormVisible: false,
      temp: {
        id: null,
        type: null,
        title: null,
        image: null,
        content: null,
      },
      typeList: [
        {
          value: "拆改",
          label: "拆改",
        },
        {
          value: "水电改造",
          label: "水电改造",
        },
        {
          value: "防水",
          label: "防水",
        },
        {
          value: "木工",
          label: "木工",
        },
        {
          value: "瓷砖",
          label: "瓷砖",
        },
        {
          value: "泥瓦工",
          label: "泥瓦工",
        },
        {
          value: "榻榻米",
          label: "榻榻米",
        },
        {
          value: "地漏",
          label: "地漏",
        },
        {
          value: "电视背景墙",
          label: "电视背景墙",
        },
        {
          value: "地板",
          label: "地板",
        },
        {
          value: "美缝",
          label: "美缝",
        },
        {
          value: "油漆",
          label: "油漆",
        },
        {
          value: "适老化装修指南",
          label: "适老化装修指南",
        },
        {
          value: "儿童房装修指南",
          label: "儿童房装修指南",
        },
      ],
      searchParam: {
        type: null,
        title: null,
        tenantId: this.$store.state.app.userInfo.tenantId,
      },
      tableUrl: getDecorationTipsListUrl,
      headers: [
        {
          prop: "type",
          label: "分类",
        },
        {
          prop: "title",
          label: "标题",
        },
        {
          prop: "insertTime",
          label: "创建时间",
        },
      ],
    };
  },
  methods: {
    submit() {
      if (!this.temp.title || this.temp.title == "") {
        this.$message.error("请输入标题！");
        return;
      }
      if (!this.temp.image || this.temp.image == "") {
        this.$message.error("请上传封面图！");
        return;
      }
      if (!this.temp.type || this.temp.type == "") {
        this.$message.error("请选择分类！");
        return;
      }
      if (!this.temp.content || this.temp.content == "") {
        this.$message.error("请输入内容！");
        return;
      }
      if (this.dialogTitle == "编辑") {
        let params = {
          id: this.temp.id,
          type: this.temp.type,
          title: this.temp.title,
          image: this.temp.image,
          content: this.temp.content,
          userId: this.$store.state.app.userInfo.userId,
        };
        this.$axios.post(`${addOrUpdateTipsInfo}`, params).then((res) => {
          if (res.code == 200) {
            this.dialogFormVisible = false;
            this.$refs.list.search();
          }
        });
      } else if (this.dialogTitle == "新增") {
        let params = {
          type: this.temp.type,
          title: this.temp.title,
          image: this.temp.image,
          content: this.temp.content,
          userId: this.$store.state.app.userInfo.userId,
        };
        this.$axios.post(`${addOrUpdateTipsInfo}`, params).then((res) => {
          if (res.code == 200) {
            this.dialogFormVisible = false;
            this.$refs.list.search();
          }
        });
      }
    },
    edit(item) {
      this.dialogTitle = "编辑";
      this.temp.id = item.id;
      this.temp.type = item.type;
      this.temp.title = item.title;
      this.temp.image = item.image;
      this.temp.content = item.content;
      this.dialogFormVisible = true;
    },
    remove(item) {
      this.$confirm("你确定要删除吗", "确定", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$axios
          .post(`${deleteTipsInfoUrl}`, null, {
            params: {
              id: item.id,
            },
          })
          .then((res) => {
            if (res.code === 200) {
              this.$message.success("取消成功");
              this.$refs.list.search();
            } else {
              this.$message.error(res.msg);
            }
          });
      });
    },
    add() {
      this.dialogTitle = "新增";
      this.temp.id = null;
      this.temp.type = null;
      this.temp.title = null;
      this.temp.image = null;
      this.temp.content = null;
      this.dialogFormVisible = true;
    },
  },
};
</script>

<style scoped lang="less">
.page {
  box-sizing: border-box;
  height: 100%;
}
</style>
